import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../utils/normalize.css";
import "../utils/css/screen.css";

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[`Design`, `Web-development`, `javascript`, `ux-ui`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="">Hello/Salut/מה נישמה/Hola</h2>

          <p>
            Your complete Creative Hub, where marketing, user experience,
            design, and digital innovation converge for exceptional brand
            experiences and stunning visual narratives. Berco Studio is your
            go-to spot for creative awesomeness where we speak four languages to
            bring your brand to life in style.
          </p>

          <h3>The Team</h3>
          <p>
            David Bercovici (Lead Designer & CMO), everybody calls me Berco. I
            am a <strong>Marketing Engineer</strong>, specialised in Marketing
            Communications (MarCom) and a <strong>Front-End Developer</strong>.
            From the information arquitecture process to the high-end final
            mockups, I have a range of design skills that equips me to create
            specialised content and strategies that suits your marketing needs.
          </p>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.berco3.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption></figcaption>
          </figure>
          <p>
            I love Basketball, I played professionally for more than 10 years,
            I'm super competitive with a strong sense of leadership. Working as
            a team is my favorite way of develop ideas and projects.
          </p>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    berco3: file(relativePath: { eq: "avatarDavidBerco3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
);
